import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

export default function OpacityElement({
  children,
  delay = 0,
  duration = 500,
}) {
  const domRef = useRef()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => setIntersecting(entry.isIntersecting))
      },
      {
        threshold: 0.1,
      }
    )

    observer.observe(domRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <div
      ref={domRef}
      className={`transition delay-[${delay}] duration-${duration} ease-in ${
        isIntersecting ? "opacity-100" : "opacity-0"
      }`}
    >
      {children}
    </div>
  )
}

OpacityElement.propTypes = {
  children: PropTypes.any,
  delay: PropTypes.oneOf([
    0, 75, 100, 150, 200, 300, 500, 700, 1000, 1500, 2000, 2500, 3000, 3500,
    4000, 4500, 5000,
  ]),
  duration: PropTypes.oneOf([0, 75, 100, 150, 200, 300, 500, 700, 1000]),
}
