import PropTypes from "prop-types";
import React from "react";

export default function Image({
  alt = "image",
  src = "",
  className = "",
  loading = "eager",
  fetchpriority = "low",
}) {
  return (
    <>
      <img
        fetchpriority={fetchpriority}
        alt={alt}
        src={src}
        className={className}
        loading={loading}
      />
    </>
  );
}

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fetchpriority: PropTypes.oneOf(["low", "high"]),
  loading: PropTypes.oneOf(["eager", "lazy"]),
  src: PropTypes.string,
  srcSet: PropTypes.array,
  size: PropTypes.oneOf(["full", "bg", "mg", "sm", "xm"]),
};
