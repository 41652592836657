import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
export default function SlideElement({
  children,
  direction = "right",
  duration = 500,
}) {
  const domRef = useRef()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => setIntersecting(entry.isIntersecting))
      },
      {
        threshold: 0.1,
      }
    )

    observer.observe(domRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  function cssClassFactory() {
    if (isIntersecting)
      return direction === "right" || direction === "left"
        ? `translate-x-[0%]`
        : "translate-y-[0%]"
    return direction === "left"
      ? `-translate-x-[100%]`
      : direction === "right"
      ? `translate-x-[100%]`
      : direction === "up"
      ? `translate-y-[100%]`
      : "-translate-y-[100%]"
  }

  return (
    <div ref={domRef}>
      <div
        className={`transform ease-in-out duration-${duration} ${cssClassFactory()}`}
      >
        <div
          className={`transition-opacity ease-in duration-300 ${
            isIntersecting ? "opacity-100" : "opacity-0"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

SlideElement.propTypes = {
  children: PropTypes.any,
  direction: PropTypes.oneOf(["right", "left", "up", "down"]),
}
