import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Header } from "./components/Header";
import SlideElement from "./components/SlideElement";
import OpacityElement from "./components/OpacityElement";
import CookieConsent from "react-cookie-consent";
const About = lazy(() => import("./pages/About"));
const Clients = lazy(() => import("./pages/Clients"));
const Products = lazy(() => import("./pages/Products"));
const Footer = lazy(() => import("./components/Footer"));

const Home = lazy(() => import("./Home"));
const GateScan = lazy(() => import("./pages/Products/GateScan"));
const IrrigaEasy = lazy(() => import("./pages/Products/IrrigaEasy"));
const IrrigaPlay = lazy(() => import("./pages/Products/IrrigaPlay"));
const Sensores = lazy(() => import("./pages/Products/Sensores"));
const Solo = lazy(() => import("./pages/Products/Solo"));
const TGate = lazy(() => import("./pages/Products/TGate"));
const Th = lazy(() => import("./pages/Products/Th"));
const router = createBrowserRouter([
  {
    path: "/Início",
    element: <Home></Home>,
  },
  {
    path: "/Sobre",
    element: <About></About>,
  },
  {
    path: "/Clientes",
    element: <Clients></Clients>,
  },

  {
    path: "/Produtos",
    element: <Products></Products>,
  },
  {
    path: "/Produtos/GateScan",
    element: <GateScan></GateScan>,
  },
  {
    path: "/Produtos/IrrigaEasy",
    element: <IrrigaEasy></IrrigaEasy>,
  },
  {
    path: "/Produtos/IrrigaPlay",
    element: <IrrigaPlay></IrrigaPlay>,
  },
  {
    path: "/Produtos/Sensores",
    element: <Sensores></Sensores>,
  },
  {
    path: "/Produtos/Solo Digital",
    element: <Solo></Solo>,
  },
  {
    path: "/Produtos/TGate",
    element: <TGate></TGate>,
  },
  {
    path: "/Produtos/Th",
    element: <Th></Th>,
  },
  {
    path: "*",
    element: <Navigate to="/Início" replace></Navigate>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <main className="bg-white h-[100%] overflow-x-hidden max-w-full max-h-fit space-y-0 ">
      <SlideElement direction="down">
        <Header />
      </SlideElement>
      <Suspense>
        <div className="h-full bg-repeat-round ">
          <RouterProvider router={router}></RouterProvider>
        </div>
        <OpacityElement>
          <Footer />
        </OpacityElement>
      </Suspense>
      <CookieConsent
        location={"bottom"}
        buttonText="Aceitar Cookies"
        cookieName="site-irrigate"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
        expires={150}
      >
        <h3 className="text-xl"> Política de Cookies</h3>
        <br></br>
        <p className="text-justify text-md">
          Este site utiliza cookies para melhorar a sua experiência de
          navegação. Ao continuar a usar este site, você concorda com o uso de
          cookies de acordo com nossa política. Ao clicar em "Aceitar Cookies",
          você concorda com o uso de cookies neste site.
        </p>
      </CookieConsent>
    </main>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
