import React, { useEffect } from "react";
import texts from "../texts.json";
import Image from "./Image";
import { Email, WhatsApp } from "@mui/icons-material";
export function Header() {
  function compare(navitem, url) {
    if (!(!!navitem && !!url)) return "";
    const result = url.includes(encodeURIComponent(navitem));
    return result ? "text-irrigate-cyan" : "";
  }
  return (
    <header className="z-10  w-full">
      <nav className=" w-full px-8 lg:px-4 bg-irrigate-gray py-3 shadow-md grid grid-cols-1 lg:grid-cols-2  ">
        <div className="flex w-full justify-between lg:justify-normal  gap-2 mt-2 mb-4 lg:mb-0 space-x-5">
          <div className="flex justify-center w-full lg:w-[60%]">
            <Image
              fetchpriority="high"
              loading="eager"
              className=" max-h-20  w-auto cursor-pointer  rounded-xl hover:bg-irrigate-gray-2"
              src="/img/original/logo/logo completa.webp"
              srcSet="/img/800/logo/logo completa.webp 800w, /img/600/logo/logo completa.webp 600w, /img/400/logo/logo completa.webp 400w,  "
              alt="Logo"
            />
          </div>
          <div className="  text-irrigate-blue font-semibold text-end lg:text-start  place-self-end space-y-3 hidden lg:block">
            {texts.company.contact.map((con, i) => (
              <div className="flex text-sm space-x-3">
                <WhatsApp></WhatsApp>
                <a
                  key={i}
                  target="__blank"
                  className="text-sm "
                  href={`${con.whatsapp}`}
                >
                  {"  "} {con.telephone}
                </a>
              </div>
            ))}
            <div className="flex text-sm space-x-3">
              <Email></Email>
              <a
                target="__blank"
                className="text-sm"
                href={`mailto:${texts.company.email}`}
              >
                {texts.company.email}
              </a>
            </div>
          </div>
        </div>
        <hr className="lg:hidden border-none  bg-irrigate-blue  h-[0.1vh] "></hr>
        <div
          className={`text-sm lg:text-lg mt-2 lg:ml-32 flex justify-between space-x-5 text-irrigate-blue  font-semibold items-center`}
        >
          {texts.navbar.map((navItem) => (
            <div
              className={
                "text-center py-2 pr-4 " +
                compare(navItem, window?.location?.pathname)
              }
            >
              <a href={`/${navItem}`}>{navItem}</a>
            </div>
          ))}
        </div>
      </nav>
    </header>
  );
}
